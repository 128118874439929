<template>
    <div class="hdFoundChart"></div>
</template>

<script>
export default {
    name: '',
    data() {
        return {}
    },
    methods: {
        setChart() {
            //智联招聘
            let zl=20;
            //51job
            let job=13;
            //BOSS直聘
            let boss=45;
            //内部推荐
            let inner=17;
            //其他
            let other=5;
            this.$http
                .get('https://hr.estar-info.com/hrApiController.do?channelCatagory')
                .then(
                    response => {//successcallback
                        zl = response.body.obj.zl;
                        job = response.body.obj.job;
                        boss = response.body.obj.boss;
                        inner = response.body.obj.inner;
                        other = response.body.obj.other;
                        let option = {
                            xAxis: {
                                type: 'category',
                                data: ['智联招聘','51job','内部推荐','BOSS直聘','其他'],
                                splitLine:{
                                    show:false
                                },
                                axisLabel: {
                                    color: '#61B9C8',
                                    fontSize: 9
                                },
                            },
                            tooltip: {
                                trigger: 'axis',
                            },
                            grid: {
                                top: '12%',
                                left: '10%',
                                right: '10%',
                                bottom: '10%',
                                containLabel: false
                            },
                            barWidth: 20,
                            yAxis: {
                                type: 'value',
                                splitLine:{
                                    show:false
                                },
                                axisLabel: {
                                    color: '#61B9C8',
                                    fontSize: 9
                                },
                            },
                            series: [
                                {
                                    data: [
                                        {
                                            value: zl,
                                            itemStyle: {
                                                color: '#c2232a'
                                            }
                                        },
                                        {
                                            value: job,
                                            itemStyle: {
                                                color: '#fe672c'
                                            }
                                        },
                                        {
                                            value: inner,
                                            itemStyle: {
                                                color: '#00CCFF'
                                            }
                                        },
                                        {
                                            value: boss,
                                            itemStyle: {
                                                color: '#a262f2'
                                            }
                                        },
                                        {
                                            value: other,
                                            itemStyle: {
                                                color: '#61B9C8'
                                            }
                                        }

                                    ],
                                    type: 'bar'
                                }
                            ]
                        };

                        let myChart = this.$echarts(this.$el);
                        myChart.clear();
                        myChart.resize()
                        myChart.setOption(option);
                    },
                    response => {//errorcallback
                        alert("失败！");
                        console.log(JSON.stringify(response))//错误信息
                    })



        }
    },
    mounted() {
        this.setChart()
    },
}
</script>

<style lang="less" scoped>
.hdFoundChart {
    height: 100%;
    width: 100%;
}
</style>
